import React from 'react';

import styled from '@emotion/styled';

import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo';
import InstagramIcon from '../../../components/Icons/InstagramIcon';
import TwitterIcon from '../../../components/Icons/TwitterIcon';
import Youtube from '../../../components/Icons/Youtube';
import { ButtonLink, InternalNavLink } from '../../../components/Link/Link';
import {
  footerNavCompanyConfig,
  footerNavContactConfig,
  footerNavLegalConfig,
} from './footerNavConfig';

const Footer = () => {
  return (
    <Container>
      <FooterNavInfo>
        <CompanyLogo />
        <p>
          With our user-friendly platform, finding the perfect locum assignment
          has never been easier. Customize your search based on specialty,
          location, and availability, and browse through a wide range of
          opportunities tailored to your preferences. Say goodbye to the hassle
          of traditional job hunting and embrace a seamless and efficient
          process.
        </p>
        <SocialsContainer>
          <ButtonLink to="/">
            <InstagramIcon />
          </ButtonLink>
          <ButtonLink to="/">
            <TwitterIcon />
          </ButtonLink>
          <ButtonLink to="/">
            <Youtube />
          </ButtonLink>
        </SocialsContainer>
      </FooterNavInfo>
      <FooterNavContainer>
        <Ul>
          <UlShell>
            <Li>Company</Li>
            <Ul2>
              {footerNavCompanyConfig.map((nav) => (
                <InternalNavLink className="footer" key={nav.title} to="/">
                  {nav.title}
                </InternalNavLink>
              ))}
            </Ul2>
          </UlShell>
          <UlShell>
            <Li>Legal</Li>
            <Ul2>
              {footerNavLegalConfig.map((nav) => (
                <InternalNavLink className="footer" key={nav.title} to="/">
                  {nav.title}
                </InternalNavLink>
              ))}
            </Ul2>
          </UlShell>
          <UlShell>
            <Li>Contact</Li>
            <Ul2>
              {footerNavContactConfig.map((nav) => (
                <InternalNavLink className="footer" key={nav.title} to="/">
                  {nav.title}
                </InternalNavLink>
              ))}
            </Ul2>
          </UlShell>
        </Ul>
      </FooterNavContainer>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  /* border: 2px solid red; */
  display: flex;
  gap: 5rem;
  padding: 4.12rem 7.12rem 5.25rem;
  font-weight: 400;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.blackBlack3};

  @media (max-width: 480px) {
    flex-direction: column;
    /* border: 2px solid yellow; */
    padding: 1.5rem;
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const FooterNavInfo = styled.div`
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const FooterNavContainer = styled.div`
  flex-basis: 55%;
  display: flex;
`;

const Ul = styled.ul`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  gap: 2rem;
  color: ${({ theme }) => theme.palette.blackBlack2};
  list-style: none;
  margin: 0;
  padding: 0;

  & > * > * {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* border: 2px solid yellow; */
  }
`;

const UlShell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const Ul2 = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  @media (max-width: 480px) {
    gap: 1rem;
  }
`;

const Li = styled.li`
  font-size: 1.25rem;
  font-weight: 600rem;
`;
