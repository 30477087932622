import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

export const InternalNavLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.greyGrey1};
  font-weight: 400;

  &.footer {
    color: ${({ theme }) => theme.palette.blackBlack3};
    font-size: 1.125rem;
  }

  &.hamburger {
    color: ${({ theme }) => theme.palette.purplePurple1};
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

export const ButtonLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.greyGrey1};
  font-weight: 500;

  /* border: 1px solid green; */
`;

interface INavItem {
  icon: React.ReactNode;
  children: string;
  path: string;
}

export const NavBoxItem: React.FC<INavItem> = ({ icon, path, children }) => {
  return (
    <NavigationBoxLink
      className={(navData) => (navData.isActive ? 'active' : '')}
      to={path}
    >
      {icon}
      {children}
    </NavigationBoxLink>
  );
};

export const NavigationBoxLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.greyGrey2};
  display: flex;
  gap: 1.25rem;
  /* background-color: ${({ theme }) => theme.palette.mainBlue}; */

  padding: 0.81rem 1.31rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  &:hover {
    /* background-color: #d2dfff28; */
    color: ${({ theme }) => theme.palette.greyGrey1};
  }

  &.active {
    background-color: #2858d128;
    color: ${({ theme }) => theme.palette.mainBlue};
  }
`;
