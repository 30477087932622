import React from 'react';

import styled from '@emotion/styled';

import CompanyLogo from '../../../components/CompanyLogo/CompanyLogo';
import CloseIcon from '../../../components/Icons/CloseIcon';
import { InternalNavLink } from '../../../components/Link/Link';
import { navConfig } from '../navConfig';

interface IHamburgerMenu {
  open: boolean;
  toggleNav: (...arg: any) => void;
}

const HamburgerMenu: React.FC<IHamburgerMenu> = ({ open, toggleNav }) => {
  const handleNavMenuItemClick = (event: any) => {
    toggleNav();
  };

  return (
    <Container className={open ? '' : 'close'}>
      <StyledNav className={open ? '' : 'close'}>
        <LogoAndCloseBtnArea>
          <div onClick={handleNavMenuItemClick}>
            <CompanyLogo />
          </div>
          <CloseIconContainer
            className={open ? 'open' : ''}
            onClick={toggleNav}
          >
            <CloseIcon />
          </CloseIconContainer>
        </LogoAndCloseBtnArea>

        <NavElementContainer>
          {navConfig.map((nav) => (
            <InternalNavLink
              to={nav.path}
              key={nav.title}
              onClick={handleNavMenuItemClick}
              className="hamburger"
            >
              {nav.title}
            </InternalNavLink>
          ))}
        </NavElementContainer>
      </StyledNav>
    </Container>
  );
};

export default HamburgerMenu;

const Container = styled.div`
  display: None;
  height: 100vh;
  position: fixed;
  background-color: #8080804b;

  @media screen and (max-width: 480px) {
    width: 100vw;
    display: flex;
    margin-left: auto;
    z-index: 200000000;

    &.close {
      /* border: 2px solid yellow; */
      display: none;
    }
  }
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-left: auto;
  gap: 2rem;

  padding: 1.5rem;
  padding-top: 1.5rem;
  background: white;
  overflow-x: auto;
  height: 100%;

  &.close {
    display: none;
  }
`;

const LogoAndCloseBtnArea = styled.div`
  display: flex;
  /* border: 2px solid red; */
  justify-content: space-between;
`;

const CloseIconContainer = styled.div`
  color: ${({ theme }) => theme.palette.purplePurple1};
  margin-top: 0.5rem;
`;

const NavElementContainer = styled.div`
  /* border: 2px solid red; */
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
