import React from 'react';

import styled from '@emotion/styled';

import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import { ButtonLink } from '../../../components/Link/Link';

// import { HOME } from '../../../routes/routeConstants';

const WaitinListNav = () => {
  return (
    <Container>
      <ButtonLink
        to={
          'http://gem.godaddy.com/signups/1d9988c5f3ef417b80d71d27f56e6070/join'
        }
        target="_blank"
      >
        <PrimaryButton className="grey">Join wait list</PrimaryButton>
      </ButtonLink>

      <Link href="/#get-app">
        <SecondaryButton>Download our app</SecondaryButton>
      </Link>

      {/* <ButtonLink to={`${HOME}#get-app`}>
        <SecondaryButton>Download our app</SecondaryButton>
      </ButtonLink> */}
    </Container>
  );
};

const Container = styled.div`
  /* border: 2px solid green; */
  display: flex;
  gap: 2rem;

  @media (max-width: 480px) {
    display: none;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

export default WaitinListNav;
